import { Switch } from 'components/switch';
import { Modal } from 'components/ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './_surveyConfigurationsModal.scss';

function SurveyConfigurationsModal({ isOpenModal, onClose, formik }) {
  const { t } = useTranslation();
  const { values } = formik;
  const [configurations, setConfigurations] = useState([
    {
      key: 'is_collecting_nps_response',
      title: 'formBuilder.configuration.instantFeedback.title',
      subTitle: 'formBuilder.configuration.instantFeedback.subTitle',
      isChecked: values.is_collecting_nps_response,
    },
  ]);

  const configChangeHandler = (configIndex) => {
    const updatedConfigs = [...configurations];
    const currentConfig = updatedConfigs[configIndex];
    updatedConfigs[configIndex] = {
      ...currentConfig,
      isChecked: !currentConfig.isChecked,
    };
    setConfigurations(updatedConfigs);
  };

  const onSaveConfigs = () => {
    const newConfigs = {};
    configurations.forEach((config) => {
      newConfigs[config.key] = config.isChecked;
    });
    formik.setValues({ ...values, ...newConfigs });
    onClose();
  };

  return (
    <Modal
      className="survey-settings-modal"
      onClose={onClose}
      open={isOpenModal}
    >
      <Modal.Header title="Configuration" onClose={onClose} />
      <Modal.Body>
        <div className="configuration-container">
          {configurations.map((config, index) => (
            <div className="configuration" key={config.key}>
              <div className="configuration-description">
                <h5>{t(config.title)}</h5>
                <p>{t(config.subTitle)}</p>
              </div>
              <div>
                <Switch
                  onChange={() => configChangeHandler(index)}
                  checked={config.isChecked}
                />
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer primaryLabel="Update" primaryAction={onSaveConfigs} />
    </Modal>
  );
}

export default SurveyConfigurationsModal;
