import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  getConfigWidgets,
  getHapinessOverTime,
  getQuestionsAnalytics,
  getQuickStatsGraph,
  postConfigWidgets,
  getHapinessOverTimeByEntity,
  createHelpItem,
} from './dashboardActions';

const INITIAL_STATE = {
  helpItem: {
    isLoading: false,
  },
  happinessOverTime: {
    isLoading: false,
    data: {},
  },
  quickStats: {
    isLoading: false,
    data: {},
  },
  configWidgets: {
    isLoading: false,
    data: [],
  },
  enabledWidgets: [],
  questionAnalytics: {},
};

const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState: INITIAL_STATE,
  reducers: {
    resetDashboardSlice: () => INITIAL_STATE,
    setConfigWidgets: (state, action) => {
      state.configWidgets.data = action.payload;
    },
    setEnabledWidgets: (state, action) => {
      state.enabledWidgets = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHapinessOverTime.pending, (state) => {
      state.happinessOverTime.isLoading = true;
    });
    builder.addCase(getHapinessOverTime.rejected, (state) => {
      state.happinessOverTime.isLoading = false;
    });
    builder.addCase(getHapinessOverTime.fulfilled, (state, action) => {
      state.happinessOverTime.isLoading = false;
      state.happinessOverTime.data = action.payload;
    });
    builder.addCase(getHapinessOverTimeByEntity.pending, (state) => {
      state.happinessOverTime.isLoading = true;
    });
    builder.addCase(getHapinessOverTimeByEntity.rejected, (state) => {
      state.happinessOverTime.isLoading = false;
    });
    builder.addCase(getHapinessOverTimeByEntity.fulfilled, (state, action) => {
      state.happinessOverTime.isLoading = false;
      state.happinessOverTime.data = action.payload;
    });
    builder.addCase(getQuickStatsGraph.pending, (state) => {
      state.happinessOverTime.isLoading = true;
    });
    builder.addCase(getQuickStatsGraph.rejected, (state) => {
      state.happinessOverTime.isLoading = false;
    });
    builder.addCase(getQuickStatsGraph.fulfilled, (state, action) => {
      state.quickStats.isLoading = false;
      state.quickStats.data = action.payload;
    });
    builder.addCase(getQuestionsAnalytics.pending, (state, action) => {
      state.questionAnalytics[action.meta.arg.questionId] = { isLoading: true };
    });
    builder.addCase(getQuestionsAnalytics.rejected, (state, action) => {
      state.questionAnalytics[action.meta.arg.questionId].isLoading = false;
    });
    builder.addCase(getQuestionsAnalytics.fulfilled, (state, action) => {
      state.questionAnalytics[action.meta.arg.questionId].isLoading = false;
      state.questionAnalytics[action.meta.arg.questionId].data = action.payload;
    });
    builder.addCase(getConfigWidgets.pending, (state) => {
      state.configWidgets.isLoading = true;
    });
    builder.addCase(getConfigWidgets.rejected, (state) => {
      state.configWidgets.isLoading = false;
    });
    builder.addCase(getConfigWidgets.fulfilled, (state, action) => {
      state.configWidgets.isLoading = false;
      state.configWidgets.data = action.payload;
    });
    builder.addCase(postConfigWidgets.pending, (state) => {
      state.configWidgets.isLoading = true;
    });
    builder.addCase(postConfigWidgets.rejected, (state) => {
      state.configWidgets.isLoading = false;
    });
    builder.addCase(postConfigWidgets.fulfilled, (state) => {
      state.configWidgets.isLoading = false;
    });
    builder.addCase(createHelpItem.pending, (state) => {
      state.helpItem.isLoading = true;
    });
    builder.addCase(createHelpItem.rejected, (state) => {
      state.helpItem.isLoading = false;
    });
    builder.addCase(createHelpItem.fulfilled, (state) => {
      toast.success(
        'Thank you for contacting us! We have received your request and will get back to you shortly.',
      );
      state.helpItem.isLoading = false;
    });
    return builder;
  },
});
export const { resetDashboardSlice, setConfigWidgets, setEnabledWidgets } =
  dashboardSlice.actions;
export default dashboardSlice;
