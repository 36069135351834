import { onlyLettersAndSpacesRegex } from 'utils/constants/regex';
import Yup from './Yup';
import { CHECKBOX } from 'utils/constants/questionTypes';
import { customExperienceSchema } from './common';
import { isChoicesQuestion } from 'utils/helpers/surveyHelpers';

const getTextEditorErrors = (fieldName, editorText, min, max) => {
  const text = editorText?.label?.getPlainText();
  let error = '';
  if (!text || text === '') {
    error = `Empty ${fieldName} is not allowed`;
  } else if (text && text.trim() === '') {
    error = `${fieldName} can't be only blank spaces`;
  } else if (min && text.trim().length < min) {
    error = `Must be at least ${min} characters`;
  } else if (max && text.trim().length > max) {
    error = `Must not exceed ${max} characters`;
  }
  return error;
};

const optionSchema = Yup.array().of(
  Yup.mixed().test('no-blank-option', (option, { createError, path, from }) => {
    const parentQuestion = from[0]?.value;
    if (!parentQuestion || !isChoicesQuestion(parentQuestion.question_type))
      return true;
    const optionError = getTextEditorErrors('option', option, 1, 512);
    if (optionError)
      return createError({
        message: {
          error: optionError,
          questionId: parentQuestion?.id,
        },
        path,
      });
    return true;
  }),
);

const questionSchema = Yup.array().of(
  Yup.object()
    .shape({
      label: Yup.mixed(),
      question_type: Yup.string().required(),
      options: optionSchema,
      max_number_of_choices: Yup.number(),
    })
    .test('custom-question-validation', (question, { createError, path }) => {
      const questionType = question.question_type;
      let questionError = '';
      const questionTextError = getTextEditorErrors(
        'question',
        question,
        1,
        256,
      );
      if (questionTextError) {
        questionError = questionTextError;
      } else if (
        isChoicesQuestion(questionType) &&
        question.options.length < 1
      ) {
        questionError = 'Minimum of 1 option is required';
      } else if (questionType === CHECKBOX) {
        if (
          question.max_number_of_choices < 1 ||
          !Number.isInteger(question.max_number_of_choices)
        ) {
          questionError = 'Max allowed choices is required';
        } else if (question.options.length < 3) {
          questionError = 'Minimum of 3 options are required';
        } else if (question.options.length < question.max_number_of_choices) {
          questionError = "Can't have lesser options than maximum allowed";
        }
      }
      if (questionError) {
        return createError({
          message: { error: questionError, questionId: question.id },
          path: `${path}.label`,
        });
      }
      return true;
    }),
);

const sectionSchema = Yup.array().of(
  Yup.object().shape({
    questions: questionSchema,
  }),
);

const experienceTypeSchema = customExperienceSchema('Experience name');

const getQuestionKey = (question) => {
  let label = question.label ?? '';
  if (question.label?.getPlainText) {
    label = question.label?.getPlainText();
  }
  return `${question.question_type}_${label.trim().toLowerCase()}`;
};

const surveySchema = Yup.object()
  .shape({
    title: Yup.string()
      .requiredTrimmed(
        "Survey title can't be only blank spaces.",
        'Survey title is required!',
      )
      .matches(
        onlyLettersAndSpacesRegex,
        'Survey title can only contain letters',
      )
      .range(
        1,
        256,
        'Must be at least 1 character',
        'Must not exceed 256 characters',
      ),
    experience: experienceTypeSchema,
    sections: sectionSchema,
  })
  .test('custom-unique-question-labels-validation', ({ sections }) => {
    const duplicateQuestionCount = {};

    sections.forEach((section) => {
      const question = section.questions[0];
      const key = getQuestionKey(question);
      if (!duplicateQuestionCount[key]) {
        duplicateQuestionCount[key] = 0;
      }
      duplicateQuestionCount[key] += 1;
    });
    const errors = [];
    sections.forEach((section, i) => {
      const question = section.questions[0];
      const path = `sections[${i}].questions[0].label`;
      const key = getQuestionKey(question);
      if (duplicateQuestionCount[key] > 1) {
        errors.push(
          new Yup.ValidationError(
            {
              error: 'Duplicate Questions are not allowed',
              questionId: question.id,
            },
            null,
            path,
          ),
        );
      }
    });
    if (errors.length > 0) {
      throw new Yup.ValidationError(errors);
    }
    return true;
  });

export default surveySchema;
