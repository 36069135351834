import { Table } from 'antd';
import { getEmailTemplatesColumns } from 'utils/helpers/emailsManagementHelper';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmailManagementFiltersContext from 'contexts/EmailManagementContext';
import {
  deleteInviteTemplateById,
  getInviteTemplates,
} from 'redux/emailManagement/emailManagementActions';
import {
  DELETE_TEMPLATE_PREFIX,
  EMAIL_MANAGEMENT_PAGINATION_LIMIT,
} from 'utils/constants/manageEmails';

function Templates() {
  const dispatch = useDispatch();
  const {
    params,
    onChangePagination,
    inviteTemplateIdRef,
    setIsOpenInviteTemplateModal,
    setIsOpenSendEmailModal,
    inviteEmailDataRef,
  } = useContext(EmailManagementFiltersContext);
  const {
    inviteTemplates: { data: inviteTemplates, loading },
    deleteEmailItems: { loading: deleteInviteTemplatesLoading },
  } = useSelector((s) => s.emailManagement);

  const onViewTemplate = (template) => {
    inviteTemplateIdRef.current = template.id;
    setIsOpenInviteTemplateModal(true);
  };

  const onComposeEmail = (template) => {
    inviteEmailDataRef.current = {
      invite: {
        subject: template.name,
        email_json_data: template.email_json_data ?? template.html_message,
        entity_experience: template.entity_experience,
      },
    };
    setIsOpenSendEmailModal(true);
  };

  const onDeleteTemplate = (template) => {
    dispatch(
      deleteInviteTemplateById({
        params,
        itemId: template.id,
        prefix: DELETE_TEMPLATE_PREFIX,
      }),
    );
  };

  useEffect(() => {
    dispatch(getInviteTemplates({ ...params }));
  }, [params]);

  return (
    <div>
      <div className="contentWrap">
        <Table
          className="left-align"
          scroll={{ x: 'max-content' }}
          columns={getEmailTemplatesColumns(
            deleteInviteTemplatesLoading,
            onViewTemplate,
            onComposeEmail,
            onDeleteTemplate,
          )}
          dataSource={inviteTemplates.results}
          loading={loading}
          pagination={{
            showSizeChanger: false,
            current: params.page,
            total: inviteTemplates.count,
            defaultPageSize: EMAIL_MANAGEMENT_PAGINATION_LIMIT,
            onChange: onChangePagination,
          }}
        />
      </div>
    </div>
  );
}

export default Templates;
