import { createAsyncThunk } from '@reduxjs/toolkit';
import { showErrorToast } from 'utils/helpers';
import {
  hapinessOverTimeAPICall,
  quickStatsAPICall,
  questionAnalyticsAPICall,
  hapinessOverTimeByEntityAPICall,
} from 'apis/analytics';
import {
  getConfigWidgetsAPICall,
  setConfigWidgetsAPICall,
} from 'apis/configurations';
import { createHelpItemAPICall } from 'apis/cxmeter';

export const getHapinessOverTime = createAsyncThunk(
  'dashboard/getHapinessOverTime',
  async (params) => hapinessOverTimeAPICall(params).catch(showErrorToast),
);

export const getQuickStatsGraph = createAsyncThunk(
  'dashboard/getQuickStatsGraph',
  async (params) => quickStatsAPICall(params).catch(showErrorToast),
);

export const getQuestionsAnalytics = createAsyncThunk(
  'dashboard/getQuestionsAnalytics',
  async ({ questionId, filters }) => {
    try {
      return await questionAnalyticsAPICall(questionId, filters);
    } catch (error) {
      showErrorToast(error);
      throw error;
    }
  },
);

export const getConfigWidgets = createAsyncThunk(
  'dashboard/getConfigWidgets',
  async () => getConfigWidgetsAPICall().catch(showErrorToast),
);

export const postConfigWidgets = createAsyncThunk(
  'dashboard/postConfigWidgets',
  async (data) => setConfigWidgetsAPICall(data).catch(showErrorToast),
);

export const getHapinessOverTimeByEntity = createAsyncThunk(
  'dashboard/getHapinessOverTimeByEntity',
  async (params) =>
    hapinessOverTimeByEntityAPICall(params).catch(showErrorToast),
);

export const createHelpItem = createAsyncThunk(
  'dashboard/createHelpItem',
  async (params) => createHelpItemAPICall(params).catch(showErrorToast),
);
