import React, { useEffect, useRef, useState } from 'react';
import {
  Loader,
  SurveyBuilder,
  SurveyEditedModal,
  SurveyCustomerDetail,
} from 'components';
import SurveyPreviewModal from 'components/surveyPreviewModal/SurveyPreviewModal';
import { cleanSurvey, validateSurvey } from 'utils/helpers/surveyHelpers';
import { useScreenshot } from 'utils/hooks';
import { showErrorToast } from 'utils/helpers';
import { setSelectedSurvey } from 'redux/surveys/surveysSlice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { INITIAL_SURVEY } from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import {
  createEntitySurvey,
  getEntitySurveyById,
  updateEntitySurvey,
} from 'redux/surveys/surveysActions';
import { FEEDBACK_FORMS_SETTINGS_URL } from 'utils/constants/urlConstants';
import SurveyConfigurationsModal from 'components/surveyBuilder/surveyConfigurationsModal/SurveyConfigurationsModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Select } from 'components/ui';
import { getExperience } from 'redux/filters/filterActions';
import { postExperience } from 'redux/experience/experienceActions';
import { resetExperienceSlice } from 'redux/experience/experienceSlice';
import { useTranslation } from 'react-i18next';
import SimilarityConfirmModal from 'components/surveyBuilder/surveyBuilderAnswers/SimilarityConfirmModal';
import { useFormik } from 'formik';
import surveySchema from 'utils/validations/surveyValidations';
import './_FeedbackFormSurveyDetail.scss';

function SurveyDetails() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const duplicateOptionsSections = useRef({});

  const { selectedSurvey, isLoading } = useSelector((s) => s.surveys);
  const { industry_type: industryType } = useSelector(
    (s) => s.authentication.parentEntity ?? {},
  );
  const { expList } = useSelector((s) => s.filters);
  const newExperience = useSelector((s) => s.experience);
  const { id: surveyId } = useParams();
  const { next, expId, expName } = queryString.parse(window.location.search);

  const formik = useFormik({
    initialValues: {},
    validationSchema: surveySchema,
  });
  const surveyDetail = formik.values;

  const previewRef = useRef(null);
  const [, takeScreenshot] = useScreenshot();
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [isOpenSurveyConfigs, setIsOpenSurveyConfigs] = useState(false);
  const [questionsEdited, setQuestionsEdited] = useState(false);
  const [updatingSurvey, setupdatingSurvey] = useState(false);
  const [linkEditedQuestions, setLinkEditedQuestions] = useState(null);
  const [isOpenEditedModal, setIsOpenEditedModal] = useState(false);
  const [isOpenSimilarityModal, setIsOpenSimilarityModal] = useState(false);
  const [isSimilarErrorActive, setIsSimilarErrorActive] = useState(false);

  const setEnableCustomerDetail = (value) => {
    formik.setFieldValue('is_collecting_customer_info', value);
  };

  const handleCloseModal = () => {
    setIsOpenEditedModal(false);
    setupdatingSurvey(false);
  };

  const handlePrimaryAction = () => {
    setLinkEditedQuestions(true);
    setQuestionsEdited(false);
    handleCloseModal();
  };

  const handleSecondaryAction = () => {
    setLinkEditedQuestions(false);
    setQuestionsEdited(false);
    handleCloseModal();
  };

  const closePreviewModal = () => {
    setIsOpenPreview(false);
  };

  const handleSurveyTitleChange = (event) => {
    formik.setFieldValue('title', event.target.value);
  };

  const handleExperienceChange = (experience) => {
    if (experience.isCustom) {
      formik.setFieldValue('experience', experience);
    } else {
      const filteredExperience = expList.filter(
        (exp) => exp.id === experience,
      )[0];
      formik.setFieldValue('experience', filteredExperience);
    }
  };

  const redirectToParent = (extraPayload) => {
    if (!isEmpty(extraPayload.error)) return;
    if (next) {
      navigate(next, {
        state: location.state,
      });
    } else {
      navigate(FEEDBACK_FORMS_SETTINGS_URL);
    }
  };

  const updateSurvey = async (cleanedSurvey, preview, experienceId) => {
    if (!questionsEdited || linkEditedQuestions !== null) {
      dispatch(
        updateEntitySurvey({
          surveyId,
          survey: {
            ...cleanedSurvey,
            preview,
            experience_id: experienceId,
            link_edited_questions: linkEditedQuestions,
          },
        }),
      ).then(redirectToParent);
    }
  };

  const checkSurveyValidity = () => {
    if (!formik.isValid || !isEmpty(duplicateOptionsSections.current)) {
      return false;
    }
    return validateSurvey(surveyDetail);
  };

  const saveSurvey = async () => {
    const isSurveyValid = checkSurveyValidity();
    if (!isSurveyValid || !previewRef.current) return;
    const experienceId =
      surveyDetail?.experience?.id || newExperience?.entityData?.id;
    const isTemplate = surveyDetail?.is_template;
    const cleanedSurvey = cleanSurvey(surveyDetail, surveyId && !isTemplate);
    const preview = await takeScreenshot(previewRef.current);

    try {
      const surveyData = {
        experience: experienceId,
        survey: { ...cleanedSurvey, preview },
      };
      if (surveyId !== 'new' && !isTemplate) {
        setupdatingSurvey(true);
        updateSurvey(cleanedSurvey, preview, experienceId);
      } else {
        dispatch(createEntitySurvey(surveyData)).then(redirectToParent);
      }
    } catch (e) {
      showErrorToast(e);
    }
  };

  const handleOnSaveSurvey = async () => {
    setIsOpenSimilarityModal(false);
    if (surveyDetail?.experience?.isCustom) {
      dispatch(postExperience({ exp: surveyDetail?.experience, industryType }));
    } else {
      saveSurvey();
    }
  };

  const checkSimilarityError = () => {
    const isSurveyValid = checkSurveyValidity();
    if (!isSurveyValid) {
      return;
    }
    if (isSimilarErrorActive) {
      setIsOpenSimilarityModal(true);
    } else {
      handleOnSaveSurvey();
    }
  };

  useEffect(() => {
    if (surveyId !== 'new') {
      dispatch(getEntitySurveyById(surveyId));
    } else {
      const preSelectedExp =
        expName && expId ? { id: expId, name: expName } : null;
      formik.resetForm({
        values: {
          ...INITIAL_SURVEY,
          experience: preSelectedExp,
        },
      });
    }
    return () => {
      dispatch(setSelectedSurvey(null));
      dispatch(resetExperienceSlice());
    };
  }, [surveyId]);

  useEffect(() => {
    if (selectedSurvey) {
      formik.resetForm({ values: selectedSurvey });
    }
  }, [selectedSurvey]);

  useEffect(() => {
    dispatch(getExperience());
  }, []);

  useEffect(() => {
    if (newExperience?.entityData?.id) saveSurvey();
  }, [newExperience]);

  useEffect(() => {
    if (linkEditedQuestions != null) {
      saveSurvey();
    }
  }, [linkEditedQuestions]);

  useEffect(() => {
    if (questionsEdited && updatingSurvey) {
      setIsOpenEditedModal(true);
    }
  }, [updatingSurvey]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="fl-1 feedback-form-detail-container">
      <SurveyEditedModal
        onClose={handleCloseModal}
        isOpenModal={isOpenEditedModal}
        primaryAction={handlePrimaryAction}
        secondaryAction={handleSecondaryAction}
      />

      <div className="selected-survey-header">
        <div className="selected-survey-heading">
          <button
            type="button"
            className="button p-0"
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="mr-6" />
          </button>
          <div>
            <input
              type="text"
              value={surveyDetail?.title}
              onChange={handleSurveyTitleChange}
              className="surveyFormTitle"
              placeholder="Survey Title"
            />
            {formik.errors.title ? (
              <p className="control-error">{formik.errors.title}</p>
            ) : (
              <p>{t('formBuilder.surveySubtitle')}</p>
            )}
          </div>
        </div>
        <div className="builder-actions">
          <button
            type="button"
            className="button button-secondary"
            onClick={() => setIsOpenSurveyConfigs(true)}
          >
            {t('formBuilder.configuration.title')}
          </button>
          <button
            type="button"
            className="button button-primary"
            onClick={checkSimilarityError}
          >
            {t('formBuilder.save')}
          </button>
          <button
            type="button"
            className="button button-secondary"
            onClick={() => setIsOpenPreview(true)}
          >
            {t('formBuilder.preview')}
          </button>
        </div>
      </div>
      <div>
        <h1 className="m-0 h5">{t('formBuilder.customerExperience.title')}</h1>
        <p className="m-0">{t('formBuilder.customerExperience.subTitle')}</p>
      </div>
      <div className="experience-dropdown">
        <Select
          name="entity_experience"
          value={parseInt(surveyDetail?.experience?.id || expId, 10)}
          placeholder="Select Experience Type *"
          options={expList.map(({ name, id }) => ({ label: name, value: id }))}
          onChange={handleExperienceChange}
          allowCustomOption
          customOptionPlaceholder="Enter Custom Experience *"
        />
        {formik.errors.experience ? (
          <span className="control-error">{formik.errors.experience}</span>
        ) : null}
      </div>
      {surveyDetail?.sections && (
        <>
          <SurveyBuilder
            surveySections={surveyDetail.sections}
            setQuestionsEdited={setQuestionsEdited}
            setIsSimilarErrorActive={setIsSimilarErrorActive}
            formik={formik}
            duplicateOptionsSections={duplicateOptionsSections}
          />
          <SurveyCustomerDetail
            enableCustomerDetail={surveyDetail.is_collecting_customer_info}
            setEnableCustomerDetail={setEnableCustomerDetail}
          />
          <SurveyPreviewModal
            isOpenModal={isOpenPreview}
            survey={surveyDetail}
            previewRef={previewRef}
            onClose={closePreviewModal}
          />
        </>
      )}
      {isOpenSurveyConfigs ? (
        <SurveyConfigurationsModal
          isOpenModal
          onClose={() => setIsOpenSurveyConfigs(false)}
          formik={formik}
        />
      ) : null}
      <SimilarityConfirmModal
        onClose={() => setIsOpenSimilarityModal(false)}
        isOpenModal={isOpenSimilarityModal}
        primaryAction={handleOnSaveSurvey}
        secondaryAction={() => setIsOpenSimilarityModal(false)}
      />
    </div>
  );
}
export default SurveyDetails;
