import {
  Calendar,
  Select as AntDSelect,
  Space,
  Switch,
  TimePicker,
  DatePicker,
} from 'antd';
import { Select } from 'components/ui';
import { useMemo, useRef, useState } from 'react';
import {
  DAYS_OPTIONS,
  REPEAT_OPTIONS,
  TIME_FORMAT,
} from 'utils/constants/manageEmails';
import { CalendarOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import useClickOutside from 'utils/hooks/useClickOutside';

const { Option } = AntDSelect;

export function EndSchedule({ formik }) {
  const endSchedulePopupRef = useRef();
  const [selectedOption, setSelectedOption] = useState('on');
  const [showCalendar, setShowCalendar] = useState(false);
  const ref = useClickOutside({ onClickOutside: () => setShowCalendar(false) });

  const handleSelectChange = (value) => {
    if (value !== 'on') {
      formik.setFieldValue(
        'reminder_schedules.ends_at',
        dayjs().add(30, 'days'),
      );
    }
    setSelectedOption(value);
  };

  const onDateChange = (date, { source }) => {
    formik.setFieldValue('reminder_schedules.ends_at', date);
    if (source === 'date') {
      setShowCalendar(false);
    }
  };

  const disableDates = (current) => {
    const scheduledAtDate = formik.values.scheduled_at.date;
    return (
      (current && current.isBefore(dayjs(scheduledAtDate), 'day')) ||
      current.isSame(dayjs(scheduledAtDate), 'day')
    );
  };

  const selectedDate = formik.values.reminder_schedules.ends_at;
  const selectedOptionText = useMemo(() => {
    let option = selectedOption;
    if (option === 'on' && selectedDate) {
      option = dayjs(selectedDate).format('DD MMM YYYY');
    }
    return option;
  }, [selectedDate, selectedOption]);

  return (
    <div
      className="time-dropdown"
      ref={endSchedulePopupRef}
      style={{ position: 'relative' }}
    >
      <p className="m-0">End</p>
      <div
        ref={ref}
        role="presentation"
        style={{ position: 'absolute', bottom: 40, zIndex: 1100 }}
      >
        {showCalendar ? (
          <Calendar
            onSelect={onDateChange}
            value={dayjs(selectedDate)}
            fullscreen={false}
            disabledDate={disableDates}
            style={{
              width: 300,
              maxWidth: '75vw',
              border: '1px solid #e7e8ea',
            }}
          />
        ) : null}
      </div>
      <Space direction="vertical">
        <AntDSelect
          getPopupContainer={() => endSchedulePopupRef.current}
          value={selectedOptionText}
          placement="topLeft"
          onChange={handleSelectChange}
          style={{ width: 220, height: 40 }}
          suffixIcon={
            selectedOption === 'on' ? (
              <CalendarOutlined
                onClick={(e) => {
                  e.stopPropagation();
                  setShowCalendar(true);
                }}
                style={{ color: '#de0024', fontSize: '14px' }}
              />
            ) : null
          }
        >
          <Option value="never">Never</Option>
          <Option value="on">On</Option>
        </AntDSelect>
      </Space>
      {formik.touched.reminder_schedules?.ends_at && (
        <span className="control-error">
          {formik.errors.reminder_schedules?.ends_at}
        </span>
      )}
    </div>
  );
}

function EmailSchedule({ formik }) {
  const { errors, values, touched, setFieldValue } = formik;
  const emailScheduleInputsRef = useRef();
  const timePickerPopupRef = useRef();
  const disabledDays = useRef({});

  const onRepeatChange = (repeatType) => {
    setFieldValue('reminder_schedules.reminder_type', repeatType);
  };
  const onDaysChange = (selectedDays) => {
    const days = {};
    selectedDays.forEach((d) => {
      const dayIndex = DAYS_OPTIONS.findIndex((day) => day.value === d);
      if (dayIndex > -1) {
        days[DAYS_OPTIONS[(dayIndex - 2 + 7) % 7].value] = true;
        days[DAYS_OPTIONS[(dayIndex - 1 + 7) % 7].value] = true;
        days[DAYS_OPTIONS[(dayIndex + 1) % 7].value] = true;
        days[DAYS_OPTIONS[(dayIndex + 2) % 7].value] = true;
      }
    });
    disabledDays.current = { ...days };
    setFieldValue('reminder_schedules.custom_reminder_day', selectedDays);
  };
  const onTimeChange = (time) => {
    setFieldValue('reminder_schedules.reminder_time', time);
  };

  const reminderSchedule = values.reminder_schedules;

  const getIsToday = () =>
    dayjs(values.scheduled_at.date).isSame(dayjs(), 'day');
  const getNextHour = () => dayjs().add(1, 'hour').add(5, 'minute');

  const disableDates = (current) => {
    const nextHourWithExtraMinutes = getNextHour();
    const isTimeLessThanOneHour = dayjs(values.scheduled_at.time).isBefore(
      nextHourWithExtraMinutes,
    );
    if (isTimeLessThanOneHour) {
      return current && current < dayjs().endOf('day');
    }
    return current && current.isBefore(dayjs().startOf('day'));
  };

  const disabledTime = () => {
    const nextHourWithExtraMinutes = getNextHour();
    const isToday = getIsToday();
    if (isToday) {
      return {
        disabledHours: () => [...Array(nextHourWithExtraMinutes.hour()).keys()],
        disabledMinutes: (selectedHour) => {
          if (selectedHour === nextHourWithExtraMinutes.hour()) {
            return [...Array(nextHourWithExtraMinutes.minute()).keys()];
          }
          return [];
        },
      };
    }
    return {};
  };

  return (
    <div className="email-schedule-container">
      <div className="schedule-label">
        <Switch
          name="show_email_schedule"
          className="switch-input"
          onChange={(checked) => setFieldValue('show_email_schedule', checked)}
          checked={values.show_email_schedule}
        />
        <p>Schedule Email</p>
      </div>
      {values.show_email_schedule ? (
        <div>
          <div
            className="schedule-params-container"
            ref={emailScheduleInputsRef}
          >
            <div className="schedule-email-date">
              <p className="m-0">Date</p>
              <DatePicker
                inputReadOnly
                disabledDate={disableDates}
                format="DD MMM YYYY"
                className="date-time-picker-input"
                value={values.scheduled_at.date}
                getPopupContainer={() => emailScheduleInputsRef.current}
                onChange={(date) => setFieldValue('scheduled_at.date', date)}
              />
              {touched.scheduled_at?.date && (
                <span className="control-error">
                  {errors.scheduled_at?.date}
                </span>
              )}
            </div>
            <div className="time-dropdown">
              <p className="m-0">Time</p>
              <TimePicker
                showNow={!getIsToday()}
                defaultOpenValue={
                  getIsToday()
                    ? values.scheduled_at.time ?? getNextHour()
                    : null
                }
                className="date-time-picker-input"
                placement="topLeft"
                use12Hours
                onsc
                value={values.scheduled_at.time}
                format="h:mm A"
                inputReadOnly
                disabledTime={disabledTime}
                onChange={(time) => setFieldValue('scheduled_at.time', time)}
                getPopupContainer={() => emailScheduleInputsRef.current}
              />
              {touched.scheduled_at?.time && (
                <span className="control-error">
                  {errors.scheduled_at?.time}
                </span>
              )}
            </div>
          </div>
          {formik.submitCount > 0 &&
            errors.scheduled_at &&
            typeof errors.scheduled_at === 'string' && (
              <span className="control-error">{errors.scheduled_at}</span>
            )}
        </div>
      ) : null}
      <div className="schedule-label">
        <Switch
          name="show_reminder_schedule"
          className="switch-input"
          onChange={(checked) =>
            setFieldValue('show_reminder_schedule', checked)
          }
          checked={values.show_reminder_schedule}
        />
        <p>Schedule Reminder</p>
      </div>
      {values.show_reminder_schedule ? (
        <div className="schedule-params-container">
          <div className="repeat-dropdown">
            <p className="m-0">Repeat</p>
            <Select
              contentClassName="align-top"
              name="reminder_type"
              placeholder="Select"
              options={REPEAT_OPTIONS}
              onChange={onRepeatChange}
              value={reminderSchedule.reminder_type}
              chevronStyles={{ color: '#de0024' }}
            />
            {touched.reminder_schedules?.reminder_type && (
              <span className="control-error">
                {errors.reminder_schedules?.reminder_type}
              </span>
            )}
          </div>
          {reminderSchedule?.reminder_type === 'Custom' ? (
            <div className="days-dropdown">
              <p className="m-0">Repeat on (Days)</p>
              <Select
                isMultiSelect
                contentClassName="align-top"
                name="reminder_type"
                placeholder="Select"
                options={DAYS_OPTIONS}
                onChange={onDaysChange}
                value={reminderSchedule.custom_reminder_day}
                chevronStyles={{ color: '#de0024' }}
                disabledItems={disabledDays.current}
              />
              {touched.reminder_schedules?.custom_reminder_day && (
                <span className="control-error">
                  {errors.reminder_schedules?.custom_reminder_day}
                </span>
              )}
            </div>
          ) : null}
          <div className="time-dropdown" ref={timePickerPopupRef}>
            <p className="m-0">Time</p>
            <TimePicker
              className="date-time-picker-input"
              placement="topLeft"
              use12Hours
              value={
                reminderSchedule.reminder_time
                  ? dayjs(reminderSchedule.reminder_time, TIME_FORMAT)
                  : null
              }
              format="h:mm A"
              onChange={onTimeChange}
              getPopupContainer={() => timePickerPopupRef.current}
            />
            {touched.reminder_schedules?.reminder_time && (
              <span className="control-error">
                {errors.reminder_schedules?.reminder_time}
              </span>
            )}
          </div>
          {reminderSchedule?.reminder_type === 'Custom' ? (
            <EndSchedule formik={formik} />
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default EmailSchedule;
