import { RichTextEditor } from 'components/richTextEditor';
import ToEmailInput from '../ToEmailInput';
import EmailModalInput from './EmailModalInput';
import {
  EMAIL_EDITOR_TOOLBAR_CONFIG,
  EMAIL_TEMPLATES_OVERLAY,
  FEEDBACK_TEMPLATES_OVERLAY,
} from 'utils/constants/manageEmails';
import EmailSchedule from './EmailSchedule';
import { useEffect, useMemo, useRef } from 'react';
import { Icon } from 'components/ui';
import FeedbackFormSelector from '../FeedbackFormSelector';
import {
  findSurveyLink,
  hasSelectedText,
  insertSurveyLink,
} from 'utils/helpers/richTextEditorHelpers';
import SURVEY_LINK_DECORATOR from 'components/richTextEditor/Decorators';
import { emailEditorBlockRenderFunc } from 'utils/helpers/emailsManagementHelper';

function EmailComposition({ formik, setCurrentOverlayPreview }) {
  const editorRef = useRef();
  const { values, errors, touched } = formik;
  const {
    updateHTMLEditorState,
    html_message: htmlMessage,
    email_json_data: emailJsonData,
  } = values;

  const addSurveyLinkHandler = async () => {
    const editorState = editorRef.current?.getEditorState();
    const isSelectedText = hasSelectedText(editorState);
    if (!isSelectedText) {
      formik.setFieldError(
        'link_survey',
        'Please selecte a text to link survey',
      );
      return;
    }
    const newEditorState = insertSurveyLink(editorState);
    editorRef.current.updateState(newEditorState);
  };

  useEffect(() => {
    if (editorRef && updateHTMLEditorState) {
      editorRef.current.updateState(emailJsonData ?? htmlMessage);
      formik.setFieldValue('updateHTMLEditorState', false);
    }
  }, [updateHTMLEditorState]);

  const isSurveyLinkPresent = useMemo(() => {
    return findSurveyLink(emailJsonData);
  }, [emailJsonData]);

  return (
    <>
      <ToEmailInput formik={formik} isEmailModal />
      <EmailModalInput
        label="Subject"
        placeholder="Enter Subject"
        id="subject"
        formik={formik}
      />
      <div>
        <div className="email-body">
          <RichTextEditor
            ref={editorRef}
            showCustomLink
            setState={(message) =>
              formik.setFieldValue('email_json_data', message)
            }
            content={values.email_json_data}
            toolbarConfig={EMAIL_EDITOR_TOOLBAR_CONFIG}
            toolbarOnFocus={false}
            customDecorators={[SURVEY_LINK_DECORATOR]}
            blockRendererFn={emailEditorBlockRenderFunc}
          />
        </div>
        {touched.email_json_data || errors.link_survey ? (
          <span className="control-error">
            {errors.link_survey ??
              (formik.submitCount > 0 ? errors.email_json_data : null)}
          </span>
        ) : null}
        <button
          type="button"
          className="mt-2 cstm-btn primary-cstm-btn add-cstm-survey-link"
          onClick={addSurveyLinkHandler}
          disabled={isSurveyLinkPresent}
        >
          Link Survey
        </button>
      </div>
      <div className="add-templates-btns">
        <div>
          <button
            className="cstm-btn light-cstm-btn"
            onClick={() => setCurrentOverlayPreview(EMAIL_TEMPLATES_OVERLAY)}
          >
            <Icon type="news" />
            Import Template
          </button>
          <FeedbackFormSelector
            openFeedbackTemplates={() =>
              setCurrentOverlayPreview(FEEDBACK_TEMPLATES_OVERLAY)
            }
            selectedFeedbackForm={values.entity_experience?.title}
          />
        </div>
        {touched.entity_experience && (
          <span className="control-error">{errors.entity_experience}</span>
        )}
      </div>
      <EmailSchedule formik={formik} />
    </>
  );
}

export default EmailComposition;
