import { createSlice } from '@reduxjs/toolkit';
import getInsights from './insightsActions';

const INITIAL_STATE = {
  insights: {
    data: [],
    loading: false,
    dateTitle: new Date().getFullYear(),
  },
};

const insightsSlice = createSlice({
  name: 'insightsSlice',
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(getInsights.pending, (state) => {
      state.insights.loading = true;
    });
    builder.addCase(getInsights.rejected, (state) => {
      state.insights.loading = false;
      state.insights.data = [];
      state.insights.dateTitle = new Date().getFullYear();
    });
    builder.addCase(getInsights.fulfilled, (state, action) => {
      state.insights.loading = false;
      state.insights.data = action.payload.data;
      state.insights.dateTitle = action.payload.date_title;
    });
    return builder;
  },
});

export default insightsSlice;
