import { Icon, Spinner } from 'components/ui';
import { useEffect, useRef } from 'react';
import InsightsFiltersModal from 'components/insightsReports/modals/InsightsFiltersModal';
import { useSelector } from 'react-redux';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';
import { useReactToPrint } from 'react-to-print';
import { Empty } from 'antd';
import { isEmpty } from 'lodash';
import getInsights from 'redux/insights/insightsActions';
import { Switch } from 'components/switch';
import { useTranslation } from 'react-i18next';
import { INSIGHTS_FILTERS, LATEST_FILTER } from 'utils/constants';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import './_insights.scss';

function Insights() {
  const { t } = useTranslation();
  const dispatch = useDispatchWithErrors();
  const {
    insights: { data: insights, loading, dateTitle },
  } = useSelector((state) => state.insightsData);
  const { parentEntity } = useSelector((state) => state.authentication);
  const reportRef = useRef();

  const formik = useFormik({
    initialValues: {
      filter: INSIGHTS_FILTERS[0],
      viewBy: 'm',
      isOpenFiltersModal: false,
    },
  });

  const {
    values: { filter, viewBy },
  } = formik;

  const generateInsightsHandler = async (viewFilter) => {
    const view = viewFilter ?? viewBy;
    const { isError } = await dispatch(
      getInsights({
        year: filter.value ? dayjs(filter.value).year() : null,
        view_by: view,
      }),
    );
    if (!isError && viewFilter !== undefined) {
      formik.setFieldValue('viewBy', viewFilter);
    }
  };

  const handleViewByToggle = () => {
    generateInsightsHandler(viewBy === 'm' ? 'q' : 'm');
  };

  const printInsights = useReactToPrint({
    content: () => reportRef.current,
    pageStyle: '@page { size: auto;  margin: 15mm; } }',
    documentTitle: `${parentEntity.name} insights_${filter.label}`,
  });

  const handlePrint = () => {
    printInsights();
  };

  useEffect(() => {
    generateInsightsHandler();
  }, [filter]);

  // This useEffect will be removed when Setting page design will be updated.
  useEffect(() => {
    const mainContainer = document.querySelector('.cxmeter-main');
    const contentContainer = document.querySelector('.cxmeter-container');
    if (mainContainer && contentContainer) {
      mainContainer.style.backgroundColor = '#eff0f2';
      mainContainer.style.minHeight = '100vh';
      contentContainer.style.backgroundColor = '#eff0f2';
    }
    return () => {
      if (mainContainer && contentContainer) {
        mainContainer.style.backgroundColor = 'transparent';
        contentContainer.style.backgroundColor = 'transparent';
      }
    };
  }, []);

  return (
    <div className="insights__container" ref={reportRef}>
      {loading ? <Spinner wrapperStyles={{ position: 'fixed' }} /> : null}
      <div className="insight__header">
        <h2 className="m-0">{t('insights.title')}</h2>
        <p>{t('insights.subTitle')}</p>
      </div>
      <div className="insights__detail">
        <div className="insights__detail__header">
          <div className="insights__filters">
            <div className="insights__selected__filter">
              <p className="m-0">
                {filter.label} {t('insights.title')}
              </p>
              <h4 className="m-0">{dateTitle}</h4>
            </div>
            <div>
              <button
                onClick={() => formik.setFieldValue('isOpenFiltersModal', true)}
                className="insights__change__filter cstm-btn light-cstm-btn "
              >
                <Icon style={{ fontSize: 18, color: '#de0024' }} type="edit" />
                <span className="change__filter">Change</span>
              </button>
            </div>
          </div>
          <div className="print__insights">
            {isEmpty(insights) ? null : (
              <button
                className="cstm-btn primary-cstm-btn"
                style={{ fontSize: 16 }}
                onClick={handlePrint}
              >
                <Icon style={{ fontSize: 20, color: '#de0024' }} type="print" />
                <span>{t('insights.print')}</span>
              </button>
            )}
          </div>
        </div>
        <div className="insights__view_by_toggle">
          {filter.id === LATEST_FILTER ? null : (
            <>
              <span>Month </span>
              <Switch checked={viewBy === 'q'} onChange={handleViewByToggle} />
              <span> Quarter</span>
            </>
          )}
        </div>
        {isEmpty(insights) ? (
          <Empty description={t('insights.noDataFound')} />
        ) : (
          <div className="insights__detail__data">
            {insights.map((insight) => (
              <div key={insight.id} className="insight__data">
                <div className="insight__general__summary">
                  <h6 className="insight__filter__type">
                    {viewBy === 'm' ? 'MONTHLY INSIGHTS' : 'QUARTERLY INSIGHTS'}
                  </h6>
                  <h5 className="insight__filter__date">
                    {insight.date_range}
                  </h5>
                  <div className="insight__general__summary__detail">
                    <p>{insight.summary}</p>
                  </div>
                </div>
                <div className="insight__aspects">
                  <div className="insight__positive__aspects">
                    <div className="insight__aspect">
                      <div>
                        <h6 className="insight__title">
                          {t('insights.title')}
                        </h6>
                        <h5 className="insight__aspect_title">
                          {t('insights.results.positiveAspects')}
                        </h5>
                      </div>
                      <div className="insight__key__words">
                        <p className="key__insights_title">
                          {t('insights.results.keyInsights')}
                        </p>
                        <div className="key__word__container">
                          {insight.positive
                            .filter((item) => item.key)
                            .map((item, index) => (
                              <span className="insight__key__pill" key={index}>
                                {item.key}
                              </span>
                            ))}
                        </div>
                      </div>
                      <div className="insight__aspect__points">
                        {insight.positive
                          .filter((aspect) => aspect.key)
                          .map((aspect, i) => (
                            <div key={i} className="aspect__point">
                              <h6>
                                {i + 1}. {aspect.key} ({aspect.percentage}{' '}
                                {t('insights.results.positivePercent')})
                              </h6>
                              <p>{aspect.description}</p>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="insight__negative__aspects">
                    <div className="insight__aspect">
                      <div>
                        <h6 className="insight__title">
                          {t('insights.title')}
                        </h6>{' '}
                        <h5 className="insight__aspect_title">
                          {t('insights.results.negativeAspects')}
                        </h5>
                      </div>
                      <div className="insight__key__words">
                        <p className="key__insights_title">
                          {t('insights.results.keyInsights')}
                        </p>
                        <div className="key__word__container">
                          {insight.negative
                            .filter((item) => item.key)
                            .map((item, index) => (
                              <span className="insight__key__pill" key={index}>
                                {item.key}
                              </span>
                            ))}
                        </div>
                      </div>
                      <div className="insight__aspect__points">
                        {insight.negative
                          .filter((aspect) => aspect.key)
                          .map((aspect, i) => (
                            <div key={i} className="aspect__point">
                              <h6>
                                {i + 1}. {aspect.key} ({aspect.percentage}{' '}
                                {t('insights.results.negativePercent')})
                              </h6>
                              <p>{aspect.description}</p>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <InsightsFiltersModal formik={formik} />
    </div>
  );
}
export default Insights;
