import { onlyLettersAndSpacesRegex } from 'utils/constants/regex';
import Yup from './Yup';

const signupSchema = Yup.object().shape({
  first_name: Yup.string()
    .requiredTrimmed(
      'First name cannot be only blank spaces.',
      'First name is required!',
    )
    .matches(onlyLettersAndSpacesRegex, 'First name can only contain letters')
    .range(
      2,
      64,
      'Must be at least 2 characters',
      'Must not exceed 64 characters',
    ),
  last_name: Yup.string()
    .requiredTrimmed(
      'Last name cannot be only blank spaces.',
      'Last name is required!',
    )
    .matches(onlyLettersAndSpacesRegex, 'Last name can only contain letters')
    .range(
      2,
      64,
      'Must be at least 2 characters',
      'Must not exceed 64 characters',
    ),
  email: Yup.string().required('Email is required!').customEmail(),
  tnc: Yup.boolean().oneOf(
    [true],
    'You need to agree to our terms and conditions to sign up!',
  ),
  password: Yup.string()
    .required('Password is required!')
    .min(8, 'Password is too short - should be 8 characters minimum.')
    .max(128, 'Password is too long - should be 128 characters maximum.')
    .matches(/\D/, "Password can't be entirely numeric."),
});

export default signupSchema;
