import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import _ from 'lodash';

import { chartColors } from 'utils/constants';
import { chartHelper, keepOnlyKeys, toTitleCase } from 'utils/helpers';
import lineChartOptions from './options';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

function LineChart({
  data,
  autoScale,
  onClick,
  yScales = { min: -100, max: 100 },
  xLabel = '',
  yLabel = '',
  nps = null,
}) {
  const { t } = useTranslation();

  const parseChartData = () => {
    if (_.isEmpty(data)) {
      return {
        labels: [],
        datasets: [],
      };
    }
    const dataVals = Object.values(data);
    const { getColor } = chartHelper;
    const additionalDataKeys = ['happy', 'neutral', 'unhappy', 'total'];
    const setup = {
      labels: Object.keys(data),
      datasets: Object.keys(dataVals[0])
        .filter((key) => !additionalDataKeys.includes(key))
        .map((key, index) => ({
          label: toTitleCase(key.replace('_', ' ')),
          datasetLabel: key.replace('_', ' '),
          data: dataVals.map((d) => (d[key] !== 0 ? d[key] : undefined)),
          additionalData: keepOnlyKeys(data, additionalDataKeys),
          borderColor: getColor(index, chartColors),
          borderWidth: 2,
          backgroundColor: 'white',
          pointRadius: 5,
          datalabels: {
            display: false,
            align: 'start',
            anchor: 'start',
            color: 'black',
          },
        })),
    };

    if (nps) {
      setup.datasets.push({
        label: t('dashboard.nps'),
        datasetLabel: t('dashboard.nps'),
        data: dataVals.map(() => nps),
        borderColor: getColor(1, chartColors),
        borderWidth: 2,
        backgroundColor: 'white',
        pointRadius: 1,
        datalabels: {
          display: false,
          align: 'start',
          anchor: 'start',
          color: 'black',
        },
      });
    }

    return setup;
  };
  const chartOptions = lineChartOptions(onClick, yScales, xLabel, yLabel);
  return _.isEmpty(data) ? (
    <span>No Data Found</span>
  ) : (
    <Line
      style={{ minHeight: 300 }}
      options={_.omit(chartOptions, autoScale ? ['scales'] : [])}
      data={parseChartData()}
    />
  );
}

export default LineChart;
