/* eslint-disable no-return-await */
import {
  HAPPINESS_OVERTIME_API,
  QUESTION_ANALYTICS_API,
  QUICKSTATS_API,
  HAPPINESS_OVERTIME_BY_ENTITY_API,
  BRANCH_COMPARE_API,
  EXPERIENCE_COMPARE_API,
  DATERANGE_COMPARE_API,
} from './urls';
import { getAPICall } from './utils';

export async function quickStatsAPICall(params) {
  return await getAPICall(QUICKSTATS_API, params);
}

export async function hapinessOverTimeAPICall(params) {
  return await getAPICall(HAPPINESS_OVERTIME_API, params);
}

export async function questionAnalyticsAPICall(questionId, filters) {
  return await getAPICall(QUESTION_ANALYTICS_API(questionId), filters);
}

export async function hapinessOverTimeByEntityAPICall(params) {
  return await getAPICall(HAPPINESS_OVERTIME_BY_ENTITY_API, params);
}

export async function compareEntityAPICall(params) {
  return await getAPICall(BRANCH_COMPARE_API, params);
}

export async function compareExperienceAPICall(params) {
  return await getAPICall(EXPERIENCE_COMPARE_API, params);
}

export async function compareDateRangeAPICall(params) {
  return await getAPICall(DATERANGE_COMPARE_API, params);
}
