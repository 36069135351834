import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  EditorState,
  Modifier,
} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { isEmpty } from 'lodash';

export const uploadImageCallBack = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve({ data: { link: reader.result } });
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const insertSurveyLink = (editorState) => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();

  const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
    url: '',
    isSurveyLink: true,
  });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const contentStateWithLink = Modifier.applyEntity(
    contentStateWithEntity,
    selectionState,
    entityKey,
  );
  const newEditorState = EditorState.push(
    editorState,
    contentStateWithLink,
    'apply-entity',
  );
  const updatedEditorState = EditorState.moveFocusToEnd(newEditorState);
  return updatedEditorState;
};

export const hasSelectedText = (editorState) => {
  const selectionState = editorState.getSelection();
  if (selectionState.isCollapsed()) {
    return false;
  }
  const anchorKey = selectionState.getAnchorKey();
  const currentContent = editorState.getCurrentContent();
  const startOffset = selectionState.getStartOffset();
  const endOffset = selectionState.getEndOffset();
  const selectedText = currentContent
    .getBlockForKey(anchorKey)
    .getText()
    .slice(startOffset, endOffset);

  return selectedText.length > 0;
};

export const findSurveyLink = (content) => {
  if (!content) return false;
  let entities = content.entityMap;
  if (content instanceof ContentState) {
    entities = convertToRaw(content).entityMap;
  }
  if (isEmpty(entities)) return false;
  return !!Object.values(entities).find(
    (entity) => entity.type === 'LINK' && entity.data.isSurveyLink,
  );
};

export const getFormattedState = (newContent) => {
  let editorState;
  if (!newContent) {
    editorState = EditorState.createEmpty();
  } else if (newContent instanceof EditorState) {
    editorState = newContent;
  } else if (typeof newContent === 'string') {
    const contentBlock = htmlToDraft(newContent);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
    );
    editorState = EditorState.createWithContent(contentState);
  } else if (newContent.blocks && newContent.entityMap) {
    editorState = EditorState.createWithContent(convertFromRaw(newContent));
  } else {
    editorState = EditorState.createWithContent(newContent);
  }
  return editorState;
};
